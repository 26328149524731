import React from 'react';
import styled from 'styled-components';

import {
  SectionComponent,
  HeadlineComponent,
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 2rem;
`;

export const ParagraphComponent = styled.p`
  font-size: ${({ size }) => size || '1rem'};
  font-weight: 400;
  text-align: ${({ align }) => align && align};
  padding-bottom: ${({ pb }) => pb || '1.5rem'};
`;

const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme}) => theme.colors.primary};
  margin-bottom: 1rem;
`;

const List = styled.ol`
  margin-left: 4rem;
  margin-bottom: 1rem;
`;

const ListItem = styled.li`
margin-bottom: 1rem;
`

function FAQPage() {

  return (
    <Layout>
      <SEO
        title="FAQ"
        link="https://teampoker.com/privacy-policy"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <SectionComponent mg="1.5rem">
        <HeadlineComponent>Frequently Asked Questions (FAQ)</HeadlineComponent>
        <Container>
        <Title>
        1) What is Team Poker® (TP)
        </Title>
        <ParagraphComponent>
        Team Poker® is the Patented System that allows both Individuals and Teams to compete in poker tournaments. Any poker game can be used in TP tournaments. Tournament Directors can split the Prize Pool Payouts from 0-100% to the individuals or teams. As example a typical split might be 60% to Teams and 40% to Individuals; or, Teams could play for “Trophies” only.
        </ParagraphComponent>
        <Title>
        WHY TEAM POKER® - WE MAKE POKER A TEAM POKER®!
        </Title>
        <ParagraphComponent>
        The Patented Scoring, Seating Protocol, and Handicap Process makes SKILL MORE OF A FACTOR IN TOURNAMENT POKER. 
        </ParagraphComponent>
        <ParagraphComponent>
        Team Poker® brings the Synergism and Camaraderie to poker that only teams can. Team Poker® provides the potential for More Interest, Video and Production or Tournaments, and Local to World Championship Events. Team Poker® provides the potential for Team and Individual Betting and New Poker eSport Online.
        </ParagraphComponent>
        <Title>
        2) How is Team Poker® (TP) Played
        </Title>
        <ParagraphComponent>
        TP is a poker tournament system not a poker game. Any Poker Game can be used with the TP System. Individuals play TP tournaments games like they would any other poker tournament. There is no difference in how the poker games are played by the individuals. TP is a Team Scoring, Team Handicapping, and Seating Protocol System that Tournament Directors can structure as they see fit for the best results for their Poker Room.
        </ParagraphComponent>
        <ParagraphComponent>
        Tournament Directors (TD) determine what size teams they want in any specific tournament. Any size team is possible (e.g. Teams of 2, 3, 4, Players etc., and Classes of Teams (e.g. Men VS Women, Countries VS Countries, etc.) can overlay any tournament as well. Also, TDs can structure the team size to vary (e.g. Max and Min Size of Teams) in the same tournament using the TP Handicap System (e.g. 4 Max – 2 Min players per Team; 3 Max – 1 Min per Team … yes it is possible for teams structures of 1 Individual with the TP Handicap).
        </ParagraphComponent>
        <ParagraphComponent>
        The only change to the normal poker tournament rules (e.g. as identified in pokerTDA.com) is the TP Non-Contact Rule which does not allow Teammates to play on the same table with Opponents; Therefore, Collusion is not possible among teammates in TP tournaments.
        </ParagraphComponent>
        <Title>
        3) How does the Team Poker® (TP) Tournament System Work
        </Title>
        <ParagraphComponent>
        TEAM POKER® PATENTED PROCESS allows both Individuals and Teams to play and compete in poker tournaments for a percentage of the prize pool. Teams can use the system to maximize their skill and luck to a larger prize pool payout, and Poker Team League Play.
        </ParagraphComponent>
        <ParagraphComponent>
        TP is played for Individual Finish Positions and payouts, as is any other poker tournament. The Tournament Director determines and advertises the structure of the tournament prior to the start like any poker tournament. However, in addition to typical tournament structures the directors also determines the size of the teams playing and as mentioned the prize pool spit between teams and individuals.
        </ParagraphComponent>
        <Title>
        THREE ELEMENTS OF THE TEAMPOKER® PROCESS:
        </Title>
        <ParagraphComponent>
        TEAM POKER® - SCORING The Individual Finish Positions (IFP) of players determines payouts to Individuals like any poker tournament. It is preferable that all IFPs are calculated in the exit count, but the IFP count can start at any manageable place the directors determine. The Inverse of the IFP Count establish the Individuals’ Points that contribute to the Team Score and is used to determine the Team Finish Position and Payouts. The Tie Breaker for Team Score/ Finish Position and Payouts is the Team with the Highest IFP.
        </ParagraphComponent>
        <Title>
        SCORING – More Specific Examples
        </Title>
        <ParagraphComponent>
        TEAM POKER® is flexible so Tournament Directors can determine the structure that best fits their poker room needs; and, any size team using the handicap is possible, or tournaments can require equal sized teams to compete. Individuals place in the tournaments as they always have, based on their Individual Finish Positions (IFP) in the Finish Count. The Count can start at any manageable point in the tournament, and there can be collective finishes in large tournaments where several players have the same IFP as a result of the inability to determine and account for unique exit positions. An individual’s contribution to their Team Score (Points) is calculated from the inverse of their IFP to the full Count.
        </ParagraphComponent>
        <ParagraphComponent>
        Scoring Example: Where the Finish Count starts at 100th place: 100th place receives 1 Team Point, 99th place receives 2 points, 97th place receives 3 points, etc. … until 1st place receives 100 points). The individuals with the lowest IFP (highest finish position), and the teams with the highest COMPOSITE POINT SCORE place in the tournament and receive payouts based on the Tournament Directors pre-published percentages. There is also a tie breaker as points can be equal (e.g. tie breaker for teams with the highest IFP).
        </ParagraphComponent>
        <ParagraphComponent>
        TEAM POKER - HANDICAPPING Teams can Handicap themselves, when TDs allow team size to vary, by playing with less than the maximum number of Players per Team allowed. The TP is a “Reverse Handicap” so player can potentially maximize their team’s skill factor (e.g. If the Team Structure is 4 Max and 2 Min a team of 2 would have their team point score multiplied by the ratio of 4/2 or 2 X the team points).
        </ParagraphComponent>
        <Title>
        HANDICAPPING – Examples
        </Title>
        <ParagraphComponent>
        TEAMPOKER® HANDICAP is an optional way to play that allows team size to vary within the same tournament and can enhance the skill factor even more while making it easier to form teams. Individuals and Teams can handicap themselves to maximize their return by allowing less than the maximum number of players per team to play (‘Reverse Handicap’). Each team pays the same entry fee. The TEAMPOKER® HANDICAP is the factor or ratio created by the maximum number of players allowed per team divided by the actual. The factor is multiplied by the total team points and thus equalizes their score relative to the other teams in the tournament.
        </ParagraphComponent>
        <ParagraphComponent>
        Handicapping Example: Four players per team max. Two players play as a team and pay full team entry fee. Their handicap is 4/2 = 2. Their team points (composite of their individual points) “Y” is multiplied by their handicap for their total team score: 2 X ‘Y’ = total team score … which is compared to the other team scores to determine
        </ParagraphComponent>
        <ParagraphComponent>
        TEAM POKER® - SEATING PROTOCOL
The TP NON-CONTACT or No-Contact, Protocol does not allow Teammates to play on the same table with Opponents. There is no possibility of Collusion among teammates with the No-Contact Rule. During consolidation of tables, teammates are directed to tables without their mates. Teammates can play among themselves or Heads-Up for example to facilitate table consolidation.
A unique situation that can happen at the end of a tournament with three (3) player left and two are teammates; Therefore, to maintain the NO-Contact rule a teammate has to wait for the other table to eliminate one player before having a final table of 2 Players to finish the tournament.
        </ParagraphComponent>
        <Title>
        SEATING PROTOCOL – More Specifics & Examples
        </Title>
        <ParagraphComponent>
        TEAM POKER® NO-CONTACT TEAMPOKER® is designed for cash play and serious poker to meet all existing gaming rules and regulations (See PokerTDA.com). Seating Protocol:Randomness in seating and consolidation of tables however, takes into consideration that no players from the same team are allowed on the same table until and unless they can play heads up, or they, or their teammates, are the only players left in the tournament. Until and unless consolidation can take place, teammates may play among themselves; or, there must be enough tables left at the end of any tournament to assure no teammates are playing together on same table with an opponent.
        </ParagraphComponent>
        <ParagraphComponent>
        When teammates can compete heads up with an equal number of players left per table, or there are no other opponents left in the tournament, teammates compete for individual finish positions (IFP). Therefore, by never allowing teammates to be on the same table together with opponents, there is no potential for ‘cooperation’, ‘collusion’, ‘slow playing’, or ‘chip dumping’ among teammates; and all Poker Tournament Directors Association Rules are maintained (see PokerTDA.com).
        </ParagraphComponent>
        <ParagraphComponent>
        Seating Protocol Examples: TEAMPOKER® NON-CONTACT SEATING PROTOCOL: Follow PDF NO-CONTACT SEATING PROTOCOL to see the specifics graphic examples on the process to assure teammates are never allowed to play on the same table with each other and competitor teams are.FULL-CONTACT TEAMPOKER® is a variation which allows players from the same team to play togetheron the same table and is used for aUnique Pro Poker Player Team Play, Social, Free, and Fund Raising.We call it the TEAMPOKER® FULL-CONTACT CHALLENGE Tournament Structure.
        </ParagraphComponent>
        <Title>
        4) What is the Option for TP Full-Contact Seating Protocol
        </Title>
        <ParagraphComponent>
        The TP System offers an Optional Seating Protocol for “Full-Contact” where the Seating Protocol is Totally Random, and Teammates can play on the same table with Opponents. Also, Seating Protocol can be set so Teammates are equally distributed with Opponents on the same tables, Since Teammates are known it does not fit the true definition of “Collusion”, but it is obvious that teammates could use signals or chip dumping to help their team. Although Full-Contact TP is not the preferred TP seating protocol we maintain this option as a unique and interesting way to play with poker teams and that TDs may decide to play for Special Full-Contact Events. A tournament structure that may work with TP Full-Contact are teams of 2 players with teammates on the same tables.
        </ParagraphComponent>
        <Title>
        5) How Can Team Poker® Overlay Prize Pools
        </Title>
        <ParagraphComponent>
        Team Poker® can be used as a Team Trophy and Ranking Event Only where the Teams do not win any percentage of the prize pool, but rather are for Trophies, Rank, Added Prize Pool Payouts, or Bragging Rights. Using TP any Poker Game Tournament Can Overlay Poker Teams for Added Payouts, Ranking and Trophies.
        </ParagraphComponent>
        <Title>
        6) Can I License Team Poker® for My Poker Room
        </Title>
        <ParagraphComponent>
        Live Poker Rooms can purchase a license and Download the Poker Software from our Partners and use it through a limited license agreement in their Rooms. See the Links to our Partners to see their Licensed Offerings. 
        </ParagraphComponent>
        <ParagraphComponent>
        Online Poker Rooms can contact Team Poker® and negotiate Licensing for their Poker Rooms with Team Poker LLC. TeamPoker.com has developed a Prototype and Fundraising Tool that implements the TP Requirements for Scoring, Handicapping, and No-Contact Seating Protocol.
        </ParagraphComponent>
        <Title>
        7) What is The Team Poker (TP) Fundraising Charity Tool
        </Title>
        <ParagraphComponent>
        THE FUNDRAISING TOOL™ is a PROTOTYPE STILL IN DEVELOPMENT. However, it can be accessed by link on TeamPoker.com. The TP Fundraising Took is being built to help Fundraising Entities (e.g. charities and sports teams) focus their donation solicitations by providing a fun poker gaming environment for Fundraising Teams to compete in the TP FUNDRAISING CHALLENGE™. TP Charges NO FEE and ALL PLAY IS FREE. Also, TP does not handle any donated funds nor charge any fees. All donations go directly to the Fundraising Teams in accordance with their donation methods listed by them in THE TP RED PAGES™, or the Fundraising Teams Invitation Emails. Through THE FUNDRAISING TOOL, winners of TP Fundraising Events Collect Donations, Claim “Bragging Rights”, and can establish an ongoing Donation Events.
        </ParagraphComponent>
        <Title>
        Team Poker® Fundraising Tool
        </Title>
        <ParagraphComponent>
        Users may use at their own discretion to raise funds for their Charities or Sports Teams. No Team Poker® rights are transferred by allowing users to use the Fundraising Tool and Users are not allowed to use it for Gambling. User may use the Tool at their own risk and Team Poker® does not warranty it will work properly. It is strictly for Fundraising and Fun.
        </ParagraphComponent>
        <ParagraphComponent>
        You agree that you will use the Software in a manner that complies with all applicable laws in the jurisdictions in which you use the Software. We assume no responsibility for the actions by you, the User.
        </ParagraphComponent>
        <ParagraphComponent>
        The Fundraising Tool does not permit members to wager real money and has no ability to accept deposits as it is a “free play” site only. Play Chips (TiPs) if any in members' accounts have no monetary value and cannot be exchanged for anything of value and cannot serve as a medium of exchange. All references in the Site to "pots," "limits", "betting" or the like are solely for instructional or illustrative purposes and do not involve wagering real money.
        </ParagraphComponent>
        <ParagraphComponent>
        All donations made to any fundraising entity (FREE) on the site are final and Team Poker® takes no responsibility or warrants any FRE or Fundraising Event. If mistakes are made by you in registering for any FRE, no adjustments will be made by us to correct your mistake.
        </ParagraphComponent>
        </Container>


      </SectionComponent>
    </Layout>
  );
}

export default FAQPage;